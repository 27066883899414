<template>
  <div class="dataPack">
    <div class="title">
      <div class="pointer" @click="goNextRep(`/data/dataPackage`)">
        全部资料
      </div>
      <div v-if="detail && detail.kindName">
        <i class="iconfont icon-gengduo" />{{ detail ? detail.kindName : "" }}
      </div>
      <div v-if="detail && detail.zyName">
        <i class="iconfont icon-gengduo" />{{ detail ? detail.zyName : "" }}
      </div>
      <div v-if="detail && detail.kcName">
        <i class="iconfont icon-gengduo" />{{ detail ? detail.kcName : "" }}
      </div>
    </div>
    <div v-if="detail" class="content">
      <div class="check">
        <div class="pageck">
          <div class="pageck-title">{{ detail.tiitle }}</div>
          <!-- <div class="tips">
            <div class="tips-item">
              <div v-if="detail.addTime" class="tips-item-chil">
                <i class="iconfont icon-a-zu330" />上传时间：{{
                  detail.addTime | format("yyyy/MM/dd")
                }}
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="thisList" class="iframe-box">
          <iframe v-if="
            thisList.fileType == 'word' ||
            thisList.fileType == 'ppt' ||
            thisList.fileType == 'excel'
          " seamless :src="`https://view.officeapps.live.com/op/view.aspx?src=${thisList.materialUrl}`" width="826px"
            height="100%" frameborder="1" noResize />
          <iframe v-else seamless :src="thisList.materialUrl" width="826px" height="100%" frameborder="1" noResize />
        </div>
      </div>
      <div class="list">
        <div class="pageck">
          <div class="pageck-title">{{ detail.tiitle }}</div>
          <!-- <div class="pageck-price">
            ￥{{ detail.price ? detail.price : 0 }}
          </div> -->
        </div>
        <div v-for="(item, index) in list" :key="index" class="item pointer"
          :class="thisList.id == item.id ? 'active' : ''" @click.self="choose(item)">
          <div v-if="thisList.id == item.id" class="triangle" />
          <i class="iconfont icon-a-zu335 item-icon" @click.self="choose(item)" />
          <div class="item-title twoEllipsis" @click.self="choose(item)">
            {{ item.materialName }}
          </div>
          <img class="tips_img" src="@/assets/img/Home/Group 3999.png" alt="" @click="openKowledge(item)">
        </div>
        <!-- 暂无课程 -->
        <div v-if="!list || list.length == 0" class="nothing">
          <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
          <div class="nothing-text">暂无内容哦~</div>
        </div>
        <div v-if="
          classesId
            ? !IsPurchase
            : detail.isPurchase == 1 && detail.productPrice > 0
        " class="goumai" @click="goOrder()">
          立即购买
        </div>
        <div v-else class="goumai dolow" @click="allDolow(thisList)">
          下载当前
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose" :show-close="false">
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4" />
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getLbClassCourseById, getClassCourseById, getClass, selectClassCourseMaterialList } from '@/api/home'
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { Know } from '@/api/know'
const know = new Know()
import { getToken, getInfo } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import kowledgePoints from '@/components/kowledgePoints.vue'

export default {
  components: { kowledgePoints },
  data() {
    return {
      classesId: null,
      courseId: null,
      sectionId: null,
      type: 1, // 1:资料包 2：直播随堂资料3：录播随堂资料 4：直播课堂课件5：录播课课件
      courId: null,
      ChatId: null,
      detail: {}, // 试卷包详情
      list: {},
      thisList: {}, // 当前预览
      userInfo: null,
      packageId: null, // 资料包Id
      href: '',
      IsPurchase: false,
      kowledgePointsVisible: false
    }
  },
  async created() {
    this.classesId = this.$route.query.classesId
      ? this.$route.query.classesId : null
    this.courseId = this.$route.query.courseId
      ? this.$route.query.courseId
      : null
    this.sectionId = this.$route.query.sectionId
      ? this.$route.query.sectionId
      : null
    // this.thisList = this.list[0]

    this.userInfo = getInfo()
    if (!this.userInfo) {
      Vue.prototype.goLoginView(true)
      return
    }
    this.getClassDetail()
  },
  methods: {
    async getClassDetail() {
      await selectClassCourseMaterialList({ classesId: this.classesId, courseId: this.courseId, sectionId: this.sectionId }).then((res) => {
        this.list = res.data.docList
        this.thisList = res.data.docList[0]
        this.detail = res.data.classes
        this.IsPurchase = res.data.IsPurchase
      })
    },
    // 获取试卷包列表
    getList() {
      dataClient
        .getPackageDetails(
          10000,
          1,
          this.packageId,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.list = res.rows
          this.total = res.total
          if (res.rows && res.rows.length > 0) {
            if (this.classesId && !this.IsPurchase) {
            } else {
              this.choose(res.rows[0])
            }
          }
          if (res.code) {
            this.list = []
            this.total = 0
          }
        })
    },

    /* 资料选择 */
    choose(item) {
      console.log(item, 'iiiiiiiiiiii')
      if (
        !this.userInfo ||
        (this.classesId
          ? !this.IsPurchase
          : this.detail.isPurchase == 1 && this.detail.productPrice > 0)
      ) {
        return
      }

      item.fileType = Vue.prototype.getFileType(item.names)
      this.thisList = item
    },

    getCour() {
      if (this.type == 2 || this.type == 4) {
        getLbClassCourseById(this.courId).then((res) => {
          this.detail = res.data.details
          this.detail.IsPurchase = res.data.IsPurchase
          this.detail.productPrice = res.data.jgPrice
          this.$forceUpdate()
        })
      } else {
        getClassCourseById(this.courId).then((res) => {
          this.detail = res.data.details
          this.detail.productPrice = res.data.jgPrice
          this.detail.IsPurchase = res.data.IsPurchase
          this.$forceUpdate()
        })
      }
    },
    /* 章节资料 */
    async getChat() {
      await this.getDoc()
    },
    getDoc() {
      let courseType = 3
      let docType = 4
      if (this.type == 2 || this.type == 4) {
        courseType = 1
      } else {
        courseType = 2
      }
      if (this.type == 2 || this.type == 3) {
        docType = 1
      } else {
        docType = 2
      }
      know
        .curCourseDocsList(1, 9999, this.ChatId, docType, courseType)
        .then((res) => {
          this.list = res.rows
          for (const item of this.list) {
            item.mid = item.id
            item.names = item.filePath
            item.title = item.fileName
          }
          if (this.list.length > 0) {
            this.choose(this.list[0])
          }
        })
    },
    /* 立即购买  */
    goOrder() {
      const token = getToken()
      if (token) {
        orderClient
          .addOrders(
            this.detail.id,
            localStorage.getItem('userId'),
            this.detail.title,
            5,
            this.detail.title,
            this.detail.kind
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        this.$router.push({ name: '登录页', params: { hzc: 1 } })
      }
    },
    /* 全部下载 */
    allDolow(item) {
      const data = {
        zlId: item.id,
        linkLocation: item.materialUrl,
        name: item.materialName,
        type: 0
      }
      dataClient.downloadRecord(data)
      window.open(item.materialUrl, '_blank')
    },

    iframeAutoFit() {
      try {
        if (window != parent) {
          var a = parent.document.getElementsByTagName('IFRAME')
          for (var i = 0; i < a.length; i++) {
            if (a[i].contentWindow == window) {
              var h1 = 0
              var h2 = 0
              var d = document
              var dd = d.documentElement
              a[i].parentNode.style.height = a[i].scrollHeight + 'px'
              a[i].style.height = '10px'
              if (dd && dd.scrollHeight) {
                h1 = dd.scrollHeight
              }
              if (d.body) {
                h2 = d.body.scrollHeight
              }
              var h = Math.max(h1, h2)
              if (document.all) {
                h += 4
              }
              if (window.opera) {
                h += 10
              }
              a[i].style.height = a[i].parentNode.style.height = h + 'px'
            }
          }
        }
      } catch (ex) { }
    },

    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.id)
      })
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    }

    /* if(window.attachEvent) {
   window.attachEvent("onload", iframeAutoFit);
} else if(window.addEventListener) {
   window.addEventListener("load", iframeAutoFit, false);
}  */
  }
}
</script>
<style lang="less" scoped>
/* iframe {
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: scale(0.5);
} */
.dataPack {
  width: 1146px;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;
  margin: 0px calc(50% - 573px);
}

.title {
  width: 100%;
  height: 62px;
  line-height: 62px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(216, 216, 216, 0.7);

  padding: 0px 30px;
  display: flex;
  margin: 40px 0px;

  .iconfont {
    color: #d8d8d8;
    margin: 0px 14px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .check {
    width: 100%;
    min-height: calc(100vh - 80px - 62px - 90px - 170px - 30px);
    background: #ffffff;

    .pageck {
      border-bottom: 1px solid #f5f6fa;
      padding: 32px 25px;
      border-bottom: 1px solid #dddddd;

      .pageck-title {
        font-size: 22px;
        font-family: MicrosoftYaHei;
        line-height: 22px;
        color: #333333;
      }

      .pageck-price {
        font-size: 24px;
        font-family: DIN Regular;
        line-height: 29px;
        color: #f55752;

        margin-top: 20px;
      }

      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 15px;
        color: #666666;

        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        align-items: center;

        .tips-item {
          display: flex;

          .tips-item-chil {
            margin-right: 54px;
          }

          .iconfont {
            height: 12px;
            color: #666666;
            margin-right: 8px;
          }
        }
      }
    }

    .iframe-box {
      height: calc(100vh - 170px - 30px);
    }
  }

  .list {
    width: 288px;
    min-height: calc(100vh - 80px - 62px - 90px - 170px - 30px);
    background: #ffffff;
    padding: 0px 0px 100px;
    /*  padding: 32px 13px 32px 16px; */
    flex-shrink: 0;
    margin-left: 32px;
    position: relative;

    .pageck {
      border-bottom: 1px solid #f5f6fa;
      padding: 32px 16px 16px;

      .pageck-title {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        line-height: 22px;
        color: #333333;
      }

      .pageck-price {
        font-size: 24px;
        font-family: DIN Regular;
        line-height: 29px;
        color: #f55752;

        margin-top: 20px;
      }
    }

    .item {
      height: 72px;
      background: #fff;

      padding: 15px 13px 16px 17px;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #f5f6fa;
      position: relative;

      .triangle {
        position: absolute;
        width: 14px;
        background: #ff925f;
        transform: rotate(45deg);
        height: 14px;
        top: calc(50% - 5px);
        left: -5px;
      }

      .item-icon {
        font-size: 12px;
        color: #333;
        margin-right: 9px;
        margin-top: 5px;
      }

      .item-title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 22px;
        color: #333;
      }

      .tips_img {
        margin-top: 26px;
      }
    }

    .active {
      background: #ff925f;

      .item-icon {
        color: #ffffff;
      }

      .item-title {
        color: #ffffff;
      }
    }
  }
}

.goumai {
  position: absolute;
  bottom: 30px;
  left: 16px;
  right: 16px;
  height: 40px;
  background: #fd623f;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 40px;
  color: #ffffff;
  cursor: pointer;
}

.dolow {
  position: absolute;
  bottom: 30px;
  left: 16px;
  right: 16px;
  height: 40px;
  border: 1px solid #fd623f;
  opacity: 1;
  background: #fff;
  color: #fd623f;
}

.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}</style>
